import React, { Fragment } from 'react'
import { CurrencyFormat } from '../../../../lib/App/common/ValueFormatter'
import Translate from '../../../../lib/App/common/translator/components/translate'

const ZusatzoptionenHighlights = props => {
  const konfigurationV = props.konfigurationV || {}
  const translateParkplatz = <Translate id='ZusatzoptionenHighlights/parkplatz'>Parkplatz pro Monat</Translate>

  return (
    <Fragment>
      <tr>
        <td>{translateParkplatz}</td>
        <td><CurrencyFormat value={konfigurationV.parkplatzProMonatPreis} currency='CHF' /></td>
      </tr>
    </Fragment>
  )
}

export default ZusatzoptionenHighlights
